import Image from 'next/image'
import { Box, Flex, Text, Container, LogoIcon, useMatchBreakpoints } from '@pancakeswap/uikit'
import { styled } from 'styled-components'
import Link from 'next/link'

const BoxWrapper = styled(Box)`
  width: 100vw;
  height: calc(100vh - 56px);
  overflow: hidden;
  box-sizing: border-box;
`

const BoxInner = styled(Box)`
  width: 100%;
  height: 100%;

  background-size: cover;
  display: flex;
  align-items: center;
`

const LeftBackground = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
`

const StartLink = styled(Link)`
  border-radius: 6px;
  background: transparent;
  color: #f7931a;
  display: flex;
  width: 140px;
  min-width: 140px;
  height: 44px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s;
  align-self: center;
  &:hover {
    opacity: 0.65;
  }
`

const HomePage = () => {
  const { isMobile } = useMatchBreakpoints()
  return (
    <BoxWrapper>
      <BoxInner>
        <Container>
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{ position: 'relative', zIndex: 10, height: '100%' }}
          >
            <Flex flexDirection="column" alignItems="center" style={{ gap: 10, height: '100%', margin: 'auto' }}>
              {/* <LogoIcon width="300px" style={{alignItems:"center",alignContent:"center", justifyContent:"center", justifyItems:"center"}}/> */}
              <div style={{ display: 'flex', marginBottom: '20px' }}>
                <div
                  style={{
                    border: '1px, solid, #F7931A',
                    padding: '5px',
                    height: '40px',
                    width: '120px',
                    placeItems: 'center',
                    borderRadius: '5px',
                    display: 'flex',
                  }}
                >
                  <Image src="/chains/arbi.png" width={100} height={30} alt="" />
                </div>
                <p>&nbsp;&nbsp;&nbsp;</p>
                <div
                  style={{
                    border: '1px, solid, #F7931A',
                    padding: '5px',
                    height: '40px',
                    width: '120px',
                    borderRadius: '5px',
                    placeItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Image src="/chains/zk.png" width={100} height={30} alt="" />
                </div>
              </div>
              {/* <Text fontSize={isMobile ? 32 : 46} fontWeight={600} textAlign="center">
                <span style={{ fontFamily: 'Plus Quicksand Text 01' }}>&nbsp;</span>
              </Text> */}
              {/* <Text fontSize={isMobile ? 32 : 46} fontWeight={600} textAlign="center">
                Get Access to all
                {isMobile ? ' ' : <br />}
                your Gaming Assets
              </Text> */}
              <Text fontSize={isMobile ? 32 : 46}>
                <span style={{ color: 'white', fontWeight: 700, fontFamily: 'Plus Quicksand Text 01' }}>
                  The Road to Layer 2:
                </span>
              </Text>
              <Text fontSize={isMobile ? 32 : 46}>
                <span style={{ color: '#f7931a', fontWeight: 700, fontFamily: 'Plus Quicksand Text 01' }}>
                  Fast & Secure Swaps
                </span>
              </Text>
              <p style={{ color: '#C0BBB5', fontSize: 14, textAlign: 'center', lineHeight: '17.5px', marginBottom:"20px" }}>
                JR Swap redefines the DeFi world with fast and secure Layer 2 swaps. Start with
                <br />
                Arbitrum and zkSync, and seamlessly connect to the future of the Layer2 ecosystem
              </p>
              <StartLink href="/swap" style={{ border: '1px, solid, #f7931a' }}>
                Get Started &nbsp;
                <Image src="/efi/icons/start.png" width={20} height={20} alt="" />
              </StartLink>
              {/* <Flex flexWrap="wrap" style={{ gap: 15 }} justifyContent="center">
                <Image src="/coins/bitcoin.svg" width={30} height={30} alt="" />
                <Image src="/coins/ethereum.svg" width={30} height={30} alt="" />
                <Image src="/coins/usdt.svg" width={30} height={30} alt="" />
                <Image src="/coins/vector.svg" width={30} height={30} alt="" />
                <Image src="/coins/chainlink.svg" width={30} height={30} alt="" />
                <Image src="/coins/dai.svg" width={30} height={30} alt="" />
                <Image src="/coins/vector.svg" width={30} height={30} alt="" />
              </Flex> */}
            </Flex>
          </Flex>
          {/* <LeftBackground src="/efi/home2.svg" width={800} height={600} alt="" /> */}
        </Container>
      </BoxInner>
    </BoxWrapper>
  )
}

export default HomePage
